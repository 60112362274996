import React from 'react';
import { GridProps } from '@mui/material/Grid';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';
import { breakpoints } from '@web-for-marketing/react-ui';

interface BaseProps {
    customSpacing?: string;
    breakSpaceValue?: number;
    lazyLoad?: boolean;
}

type ChildContainerProps<C extends React.ElementType> = BaseProps & GridProps<C, { component?: C }>;

const classes = {
    gridOverride: {
        padding: 0,
        '& > *': {
            padding: '0 2%',
            boxSizing: 'border-box',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '& > *': {
                paddingLeft: '0.5rem !important',
                paddingRight: '0.5rem !important',
                width: '100%',
            },
        },
    },
    breakSpacing: (breakSpaceValue: number) =>
        ({
            '& > *': {
                marginTop: `${breakSpaceValue}rem !important`,
                marginBottom: `${breakSpaceValue}rem !important`,
                boxSizing: 'border-box',
            },
        }) as const,
    customSpacing: (customSpacing: string) =>
        ({
            '& > *': {
                padding: `${customSpacing} !important`,
                boxSizing: 'border-box',
            },
        }) as const,
} as const;

export function ChildContainer<C extends React.ElementType>({
    children,
    className,
    breakSpaceValue = 2,
    customSpacing,
    lazyLoad,
    ...other
}: ChildContainerProps<C>): JSX.Element {
    return (
        <LazyLoadContainer
            data-testid='childContainer'
            container
            wrap='wrap'
            lazyload={lazyLoad}
            className={className}
            css={[
                classes.gridOverride,
                classes.breakSpacing(breakSpaceValue),
                customSpacing ? classes.customSpacing(customSpacing) : undefined,
            ]}
            {...other}
        >
            {children}
        </LazyLoadContainer>
    );
}
